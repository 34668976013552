<template>
  <div class="about-page w-full h-screen">
    <popup-charge :want-to-make-offer="activeCharge"/>
    <div class="compressor">
      <image-compressor
        :done="getFiles"
        :scale="scale"
        :quality="quality"
        ref="compressor"
      >
      </image-compressor>
    </div>
    <div class="containerStyle mr-auto ml-auto">
      <div class="web-header w-full md:flex">
        <div class="button ret mr-6" @click="goBack">
          <svg-icon
            name="arrow"
            class="h-4 cursor-pointer md:mr-4"
            original
          />
          <div class="mr-2 retour">
            Retour
          </div>
        </div>

        <div class="">
          <div class="titre">
            Complètez les documents
          </div>

          <div class="mt-6" v-if="controleExist('Images des dégats corporels') !== -1">
            <div class="sous-titre mb-4">Images des dégats corporels</div>

            <div class="flex items-center mb-2" v-for="(item, index) in corporel" :key="index">
              <div class="mr-2 w-full">
                <image-apercu :item="item" v-if="item"/>
              </div>
              <svg-icon
                @click="deleteImage(index)"
                name="delete"
                class="h-5 cursor-pointer"
                original
              />
            </div>

            <div class="mt-5 button addImage" @click="selectedImage('corporel')">
              <div class="mr-4">Ajouter les images</div>
              <svg-icon
                name="download"
                class="h-4 cursor-pointer ico"
                original
                color="white"
              />
            </div>
          </div>

          <div class="mt-6" v-if="controleExist('Images des dégats matériels') !== -1">
            <div class="sous-titre mb-4">Images des dégats matériels</div>

            <div class="flex items-center mb-2" v-for="(item, index) in file" :key="index">
              <div class="mr-2 w-full">
                <image-apercu :item="item" v-if="item"/>
              </div>
              <svg-icon
                @click="deleteImage(index)"
                name="delete"
                class="h-5 cursor-pointer"
                original
              />
            </div>

            <div class="mt-5 button addImage" @click="selectedImage('degat')">
              <div class="mr-4">Ajouter les images</div>
              <svg-icon
                name="download"
                class="h-4 cursor-pointer ico"
                original
                color="white"
              />
            </div>
          </div>

          <div class="mt-6" v-if="controleExist('Devis/factures des réparations') !== -1">
            <div class="sous-titre mb-4">Image des devis ou factures</div>

            <div class="flex items-center mb-2" v-for="(item, index) in devis" :key="index">
              <div class="mr-2 w-full">
                <image-apercu :item="item" v-if="item"/>
              </div>
              <svg-icon
                @click="deleteImage(index)"
                name="delete"
                class="h-5 cursor-pointer"
                original
              />
            </div>

            <div class="mt-5 button addImage" @click="selectedImage('devis')">
              <div class="mr-4">Ajouter les images</div>
              <svg-icon
                name="download"
                class="h-4 cursor-pointer ico"
                original
                color="white"
              />
            </div>
          </div>

          <div class="mt-6" v-if="controleExist('Procès verbal') !== -1">
            <div class="sous-titre mb-4">Copie du Procès verbal délivré par la police ou la gendarmerie</div>

            <div class="flex items-center mb-2" v-for="(item, index) in proces" :key="index">
              <div class="mr-2 w-full">
                <image-apercu :item="item" v-if="item"/>
              </div>
              <svg-icon
                @click="deleteImage(index)"
                name="delete"
                class="h-5 cursor-pointer"
                original
              />
            </div>

            <div class="mt-5 button addImage" @click="selectedImage('proces')">
              <div class="mr-4">Ajouter les images</div>
              <svg-icon
                name="download"
                class="h-4 cursor-pointer ico"
                original
                color="white"
              />
            </div>
          </div>

          <div class="mt-6">
            <div class="mt-6 button save" @click="sendImages">
              Enregistrer
            </div>
          </div>

        </div>

      </div>
    </div>

  </div>
</template>

<script>
import imageApercu from '@/components/helper/add/imageApercu'
import http from '@/plugins/http'
import apiroutes from '@/router/api-routes'
import popupCharge from '@/components/popup/popupCharge'
import imageCompressor from 'vue-image-compressor'

export default {
  name: 'index',
  components: {
    imageApercu,
    popupCharge,
    imageCompressor
  },
  data () {
    return {
      file: [],
      fileC: [],
      proces: [],
      procesC: [],
      devis: [],
      devisC: [],
      corporel: [],
      corporelC: [],
      allDoc: [],
      allInfo: null,
      activeCharge: false,
      img: '',
      scale: 100,
      quality: 30,
      originalSize: true,
      original: {},
      indexImage: null
    }
  },

  mounted () {
    this.allDoc = this.$store.getters.manquant.allDoc
    this.allInfo = this.$store.getters.manquant
  },

  methods: {
    sendImages () {
      this.activeCharge = true
      if (this.file.length > 0) {
        this.allInfo.data.body.imagesOne = this.fileC
        this.allInfo.data.images.imagesOne = this.fileC
      }
      if (this.devis.length > 0) {
        this.allInfo.data.body.imagesFour = this.devisC
        this.allInfo.data.images.imagesFour = this.devisC
      }
      if (this.proces.length > 0) {
        this.allInfo.data.body.imagesThree = this.procesC
        this.allInfo.data.images.imagesThree = this.procesC
      }
      if (this.corporel.length > 0) {
        this.allInfo.data.body.imagesTwo = this.corporelC
        this.allInfo.data.images.imagesTwo = this.corporelC
      }

      http.patch(apiroutes.baseURL + apiroutes.sinister + '/' + this.allInfo.data.id, {
        body: this.allInfo.data.body,
        images: this.allInfo.data.images
      })
        .then(response => {
          this.activeCharge = false
          this.$router.push('/sinistre')
        })
        .catch(error => {
          this.activeCharge = false
          console.log('erreur images')
          console.log(error)
        })
    },

    goTo (index) {
      this.$router.push('/' + index)
    },

    goBack () {
      this.$router.push('/sinistre')
    },

    deleteImage (index) {
      this.allDoc.splice(index, 1)
    },

    controleExist (index) {
      return this.allDoc.indexOf(index)
    },

    selectedImage (index) {
      this.indexImage = index
      const compressor = this.$refs.compressor.$el
      compressor.click()
    },

    getFiles (obj) {
      this.img = obj.compressed.blob
      this.original = obj.original
      this.compressed = obj.compressed

      if (obj.compressed.size !== '0 kB') {
        if (this.indexImage === 'degat') {
          this.file.push(obj.compressed.file)
          this.fileC.push(obj.compressed.base64)
        }
        if (this.indexImage === 'proces') {
          this.proces.push(obj.compressed.file)
          this.procesC.push(obj.compressed.base64)
        }
        if (this.indexImage === 'devis') {
          this.devis.push(obj.compressed.file)
          this.devisC.push(obj.compressed.base64)
        }
        if (this.indexImage === 'corporel') {
          this.corporel.push(obj.compressed.file)
          this.corporelC.push(obj.compressed.base64)
        }
        this.tracker()
      } else {
        this.quality = Math.floor(Math.random() * 40)
      }
    },

    tracker () {
      if (this.file.length > 0) {
        this.allInfo.data.body.imagesOne = this.fileC
      }
      if (this.devis.length > 0) {
        this.allInfo.data.body.imagesFour = this.devisC
      }
      if (this.proces.length > 0) {
        this.allInfo.data.body.imagesThree = this.procesC
      }
      if (this.corporel.length > 0) {
        this.allInfo.data.body.imagesTwo = this.corporelC
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/style/sass/variables";

.sous-titre{
  font-weight: normal;
  font-size: 17.7655px;
  line-height: 27px;
  color: #000000;
  font-family: 'Mulish';
}

.compressor{
  display: none;
}

.about-page{
  width: 100%;
  height: 100%;
  font-family: $font-default;
  padding-bottom: 100px;
  min-height: 100vh;
}

.details{
  width: 66%;
}
.containerStyle{
  width: 71%;
}
.titre{
  font-weight: bold;
  font-size: 48px;
  line-height: 64px;
  color: #000000;
  font-family: $font-default
}
.button{
  width: 192px;
  height: 60px;
  background: #FFFFFF;
  border-radius: 20px;
  font-weight: bold;
  font-size: 24px;
  line-height: 34px;
  text-align: center;
  color: #000000;
  font-family: $font-default;
  border: none;
}
.ico{
  transform: rotate(-180deg);
}

.addImage{
  height: 35.9px;
  background: #0269AD;
  border-radius: 7.18049px;
  font-weight: normal;
  font-size: 12.5659px;
  line-height: 160%;
  color: #FFFFFF;
}
.save{
  background: #0269AD;
  color: white;
  width: 100%;
  border-radius: 6px;
}

@media screen and (max-width: 1024px) and (min-width: 770px){

}

@media screen and (max-width: 769px) and (min-width: 701px){
  .titre{
    font-size: 18px;
  }
  .retour{
    font-size: 19px;
  }
  .ret{
    padding: 15px;
    width: 23%;
    height: 45px;
    border-radius: 11.25px;
  }
  .sous-titre{
    font-size: 17px;
  }
  .save{
    height: 50px;
    font-size: 18px;
  }
}

@media screen and (max-width: 700px){
  .retour{
    display: none;
  }
  .titre{
    font-size: 20px;
  }
  .sous-titre{
    font-size: 15px;
    text-align: center;
  }
  .mt-6{
    margin-top: 20px !important;
  }
  .button{
    width: 100%;
    height: 40px;
  }
  .save{
    height: 50px;
    font-size: 18px;
  }
  .ret{
    padding: 15px;
    width: 45px;
    height: 45px;
    border-radius: 11.25px;
  }
}

@media screen and (max-width: 325px){
  .titre{
    font-size: 18px;
  }
}
</style>
